.border_button {
    border: 1px solid black;
    border-radius: 50px;
    background-color: transparent;
}

.primary_button {
    border: none;
    background-color: #FC1921;
    border-radius: 50px;
    width: 421px;
    padding: 20px 0px;
}

@media (max-width: 767px) {
    .border_button {
      width: 100%;
    }
  
    .primary_button {
      width: 100%;
    }
  }