.size30700 {
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
}

.size16400 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.size14520 {
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.size13520700 {
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
}

.size24700 {
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
}

.size14700 {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}

.size18700 {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
}

.size16700 {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.size16500 {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.size12400 {
  font-weight: 400;
  font-size: 12px;
}

.size20700 {
  font-weight: 700;
  font-size: 20px;
}

.size14500 {
  font-weight: 500;
  font-size: 14px;
}

.size1880 {
  font-weight: 800;
  font-size: 18px;
}

.mt-16px {
  margin-top: 16px;
}

.fixed-gap {
  gap: 40px 0px;
}

.csl-red-line {
  width: 100%;
  height: 1px;
  background-color: #fc1921;
  margin: 40px 0px;
}

.calendar-box-respone {
  height: 50px;
  width: 100%;
  top: 0px;
  border-radius: 5px;
  padding: 13px 9px;
  border: 1px solid #ced4da;
  position: relative;
}

.calendar-box {
  height: 50px;
  width: 100%;
  top: 0px;
  border-radius: 5px;
  padding: 13px 9px;
  border: 1px solid #ced4da;
  position: relative;
}

.calendar-icon {
  position: absolute;
  right: 9px;
  top: 12px;
}

.audience-dropdown {
  width: 100% !important;
}
.audience-dropdownss.show{
  width: 91% !important ;
}
.audience-dropdowns.show{
  width: 100% !important ;
}

.input-check {
  transform: scale(1.8);
  accent-color: #000000;
  margin-right: 18px;
}

.form-check {
  padding-left: 5px;
}

.required {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  font-weight: bold;
}

.upload-file-body input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  transform: scale(20);
  cursor: pointer;
}

.transparent-button {
  background-color: transparent;
  border: none;
}

a.design-upload {
  color: #8B8B8B;
}

a.design-upload:hover {
  color: black;
}

.upload-file-body {
  position: relative;
  border: 1px dashed rgb(212, 212, 212);
  border-radius: 10px;
  background-color: #FAFAFA;
  padding: 15px 30px;
  overflow: hidden;
  cursor: pointer;
}
.upload-file-body-border-none {
  border: none;
  padding: 0px;
}
.upload-file-body-cus {
  position: relative;
  border: 1px dashed rgb(212, 212, 212);
  border-radius: 10px;
  background-color: #FAFAFA;
  padding: 15px 30px;
  cursor: pointer;
}

.modal-700px {
  max-width: 700px !important;
}

@media (min-width: 768px) {
  .calendar-box {
    width: 341px;
  }
}

@media (max-width: 400px) {
  .calendarW {
    width: 94% !important;
    float: none !important;
  }
}
@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.dropdown-menu.location {
  height: 15rem;
  overflow-y: scroll;
}
