.form-group {
  display: flex;
  flex-direction: column;
  width: 910px;
  justify-items: left;
  padding: 40px 80px;
  margin: 40px 0px 100px 0px;
}

@media (max-width: 768px) {
  .form-group {
    width: 100%;
    padding: 30px;
    margin: 0;
  }
}

.container-lg {
  padding: 0;
}
