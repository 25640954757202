.margin-password {
    margin: 100px auto auto auto;
}

@media (max-width: 767px) {
    .margin-password {
        margin: 40px auto auto auto;
    }
}

.hideIcon{
    background-image: none !important;
}