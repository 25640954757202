.dashboardH3 {
    font-size: 20px;
    font-weight: bold;
}

.infoLabel {
    font-size: 16px;
    font-weight: 700;
    margin-top: 12px !important;
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: none !important;
    border-right: none !important;
}

.dropdown-menu.show {
    display: block;
}

.items-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.items-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.filter-body {
    width: 260px !important;
    padding: 20px 16px;
    overflow: unset;
}

.leftMenu.show {
    /* margin-left: -50px !important; */
    width: 180px !important;
}
/* .leftMenu {
    display: block;
} */

.size12600 {
    font-size: 12px;
    font-weight: 600;
}

.size12500 {
    font-size: 12px;
    font-weight: 600;
}

.size16600 {
    font-size: 16px;
    font-weight: 600;
}

.submission-body {
    width: 100% !important;
    overflow: unset;
    border-radius: 0% !important;
    padding: 0 !important;
}

.dropdown-divider {
    margin: 0;
}

.paginationCustome {
    border: none !important;
    background-color: transparent !important;
    cursor: pointer;
    color: gray !important;
    font-size: 25px;
}

.paginationCustome:hover {
    color: black !important;
}

.page-item.active .paginationCustome {
    color: black !important;
}