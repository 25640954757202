/* .table {
  min-width: 1000px !important;
  width: 100%;
  border-radius: 10px;
} */

.table thead th {
  border: none;
  padding: 0px 30px;
  font-size: 14px;
  color: #949494;
  background-color: #f5f5f5 !important;
  font-weight: 400;
  cursor: pointer;
}

.table tbody tr {
  margin-bottom: 10px;
  background-color: white;
}

.table tbody th,
.table tbody td {
  border: none;
  vertical-align: middle;
  padding-left: 30px;
  /* padding: 20px 30px; */
  font-size: 14px;
  font-weight: 600;

}

.table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.table tbody .td_radiusLeft {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table tbody .td_radiusRight {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.select:before{
  background-color: white;
}

.table-responsive{
  overflow-x: unset !important;
}
.customize1:hover .customMenu1{
  display: block !important;
}