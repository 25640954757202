@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat-ExtraBold";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

* {
  font-family: "Montserrat";
}

h6 {
  font-family: 'Montserrat-Medium';
}

:root {
  --primary-red: #FC1921;
  --primary-black: #000000;
  --secondary-medical-green: #03b3be;
  --secondary-sky-blue: #88C0F0;
  --secondary-plasma-gold: #FFA814;
  --seoncdary-maroon: #851C1D;
  --soft-white: #FCFCFA;
  --off-white: #F1EFEA;
  --warm-gray: #E2DFDA;
}

body{
  background-color: #F5F5F5;
}


main {
  width: 100%;
}

.input-check {
  transform: scale(1.8);
  accent-color: #000000;
  margin-right: 18px;
  cursor: pointer;
}
.flexCheckDefault{
  font-size: 14px;
  font-weight: 700;
}

#formFileMultiple{
  border-style: dashed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.button_pdf {
  width: 100%;
  height: 53px;
  border: dashed 1px #ced4da;
  border-radius: 5px;
  background-color: white !important;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  color: black;
  text-decoration: none;
}

.button_pdf:hover {
  color: #FC1921;
}

.custom-input {
  height: 50px;
  font-size: 16px;
}

.size14600 {
  font-size: 15px;
}

.text-wrap-mode {
  text-wrap-mode: nowrap;
}

.error-field {
  border: 1px solid rgb(220, 53, 69) !important;
}